import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, HeaderMain, MenuMobileContainer } from "./styles";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimesCircle } from "@fortawesome/free-solid-svg-icons";

// Images
import LogoRpSoft from "../../assets/images/logo_rp_soft.png";

export default function Header() {
  const [menu, setMenu] = useState(false);

  function handleMenuScroll(section) {
    setMenu(false);
    const element = document.getElementById(section);
    element.scrollIntoView({
      behavior: "smooth"
    });
  }

  return (
    <Container>
      <MenuMobileContainer openMenu={menu}>
        <button className="closed" onClick={() => setMenu(false)}>
          <FontAwesomeIcon icon={faTimesCircle} size="lg" color="#333" />
        </button>
        <nav>
          <ul>
            <Link to="#" onClick={() => handleMenuScroll("home")}>
              <li>HOME</li>
            </Link>
            <Link to="#" onClick={() => handleMenuScroll("about")}>
              <li>SOBRE</li>
            </Link>
            <Link to="#" onClick={() => handleMenuScroll("solutions")}>
              <li>SOLUÇÕES</li>
            </Link>
            <Link to="#" onClick={() => handleMenuScroll("contact")}>
              <li>CONTATO</li>
            </Link>
          </ul>
        </nav>
      </MenuMobileContainer>

      <HeaderMain>
        <div className="black-cover">
          <div className="inside">
            <div className="headerTop">
              <div className="logo">
                <img
                  src={LogoRpSoft}
                  alt="RpSoft-Sistemas"
                  title="RpSoft Sistemas"
                />
              </div>
              <nav>
                <ul>
                  <Link to="#" onClick={() => handleMenuScroll("home")}>
                    <li>HOME</li>
                  </Link>
                  <Link to="#" onClick={() => handleMenuScroll("about")}>
                    <li>SOBRE</li>
                  </Link>
                  <Link to="#" onClick={() => handleMenuScroll("solutions")}>
                    <li>SOLUÇÕES</li>
                  </Link>
                  <Link to="#" onClick={() => handleMenuScroll("contact")}>
                    <li>CONTATO</li>
                  </Link>
                </ul>
              </nav>
            </div>

            <div className="textContent">
              <h1 className="white">BEM VINDO À</h1>{" "}
              <h1 className="title">RPSOFT</h1>
              <p className="description">
                DESENVOLVEMOS SISTEMAS PARA O SUCESSO DO SEU NEGÓCIO
              </p>
            </div>
          </div>

          <div className="insideMobile">
            <div className="headerTopMobile">
              <img
                src={LogoRpSoft}
                alt="RpSoft-Sistemas"
                title="RpSoft Sistemas"
              />

              <div className="menuOpen" onClick={() => setMenu(true)}>
                <FontAwesomeIcon icon={faBars} size="sm" color="#0eaaf2" />
              </div>
            </div>
            <div className="textContentMobile">
              <h1 className="white">BEM VINDO À</h1>
              <h1 className="title">RPSOFT</h1>
              <p className="description">
                DESENVOLVEMOS SISTEMAS PARA O SUCESSO DO SEU NEGÓCIO
              </p>
            </div>
            <div></div>
          </div>
        </div>
      </HeaderMain>
    </Container>
  );
}
