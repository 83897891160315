import styled from "styled-components";

import BackgroundContact from "../../assets/images/contact.jpg";
import BackgroundAbout from "../../assets/images/sobre-bg.jpg";

export const Container = styled.div`
  background: #fff;
`;

export const SectionWhite = styled.div`
  margin: 0 auto;
  width: 100%;
  background: #fff;
`;

export const SectionSilver = styled.div`
  margin: 0 auto;
  width: 100%;
  background: #f0f0f0;
`;

export const SectionInside = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 40px 20px;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 34px;
  color: #0eaaf2;
  margin-bottom: 20px;

  @media (max-width: 980px) {
    font-size: 26px;
  }
`;

export const InsideAbout = styled.div`
  margin: 0 auto 20px;

  p {
    text-align: justify;
    margin-bottom: 20px;
    line-height: 20px;
  }
`;

export const SectionAboutContainerParallax = styled.section`
  width: 100%;
  margin: auto;
  background-image: url(${BackgroundAbout});
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .black-cover {
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    padding: 10px;
  }

  .cardInside {
    margin: 80px auto;
    text-align: center;
    color: rgba(255, 255, 255, 0.7);
    font-size: 16px;
    line-height: 25px;
  }

  @media (max-width: 980px) {
    .cardInside {
      margin: 10px auto;
      text-align: justify;
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      line-height: 18px;
    }
  }
`;

export const SolutionsCards = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  overflow: hidden;
  margin-bottom: 30px;

  h2 {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    color: #20b2aa;
  }

  h4 {
    text-align: center;
    font-size: 16px;
    color: #20b2aa;
  }

  p {
    text-align: justify;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.4);
  }

  h5 {
    text-align: left;
    font-size: 16px;
    color: #20b2aa;
    font-weight: bold;
    margin: 20px auto;
  }

  hr {
    border: 0;
    align-self: stretch;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
  }

  ul {
    width: 100%;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    margin-bottom: 40px;

    @media only screen and (max-width: 900px) {
      & {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 15px;
      }
    }

    li {
      align-self: stretch;
      background: rgba(0, 0, 0, 0.05);
      color: rgba(0, 0, 0, 0.5);
      font-weight: bold;
      padding: 10px;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background: #20b2aa;
        color: #fff;
      }

      i {
        margin-right: 10px;
      }
    }
  }
`;

export const SectionContact = styled.div`
  width: 100%;
  background: #2d2d33;
  padding-top: 40px;
  padding-bottom: 40px;

  button {
    width: 120px;
    cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  }
`;

export const ContactInput = styled.input`
  width: 100%;
  padding: 15px;
  border: ${(props) =>
    !props.fail ? "2px solid transparent" : "2px solid #85152a"};
  background: #242428;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  border-radius: 10px;
`;

export const ContactTextArea = styled.textarea`
  width: 100%;
  padding: 15px;
  border: ${(props) =>
    !props.fail ? "2px solid transparent" : "2px solid #85152a"};
  background: #242428;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
  border-radius: 10px;
`;

export const ContactIconContainer = styled.div`
  display: block;
  margin: 0 auto;
  width: 220px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 980px) {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }
`;

export const ContactIcon = styled.div`
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
  color: #ffffff;

  i {
    font-size: 44px;
    color: #ffffff;
    margin-right: 20px;
  }
  p {
    margin: 0;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
  }
  a {
    color: #ffffff;
  }

  @media (max-width: 980px) {
    margin: 20px auto;
    i {
      font-size: 30px;
      margin-right: 10px;
    }
    p {
      font-size: 12px;
    }
  }
`;
