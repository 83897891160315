import React from "react";

// import { Container } from './styles';

export default function Comger() {
  return (
    <div>
      <h1>Comger</h1>
    </div>
  );
}
