import { createGlobalStyle } from "styled-components";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "react-toastify/dist/ReactToastify.css";

export default createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root{
    scroll-behavior: smooth;
    min-height: 100%;
  }



  body {
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button {
    font-size: 14px;
    font-family: 'Ubuntu', sans-serif;
  }

  button {
   cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  a:hover{
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

`;
