import React, { useState, useEffect } from "react";
import {
  Container,
  SectionWhite,
  SectionInside,
  Title,
  InsideAbout,
  SectionAboutContainerParallax,
  SectionSilver,
  SolutionsCards,
  SectionContact,
  ContactInput,
  ContactTextArea,
  ContactIconContainer,
  ContactIcon,
} from "./styles";

// Components
import Header from "../../Components/Header";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";

import api from "../../services/api";

// Images
import ImageAbout from "../../assets/images/sobre.jpg";
import ImageAbout2 from "../../assets/images/sobre2.jpg";

export default function Main() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const [nameFail, setNameFail] = useState(false);
  const [emailFail, setEmailFail] = useState(false);
  const [phoneFail, setPhoneFail] = useState(false);
  const [messageFail, setMessageFail] = useState(false);

  const [btnActive, setBtnActive] = useState(false);

  async function handleSubmit() {
    name.length <= 0 && setNameFail(true);
    email.length <= 0 && setEmailFail(true);
    phone.length <= 0 && setPhoneFail(true);
    message.length <= 0 && setMessageFail(true);

    setLoading(true);

    try {
      const response = api.get(
        `/send-mail?name=${name}&email=${email}&phone=${phone}&message=${message}`
      );
      // const response = await api.get("/send-mail", {
      //   params: {
      //     name: name,
      //     email: email,
      //     phone: phone,
      //     message: message
      //   }
      // });
      toast.success("Seu e-mail foi enviado com sucesso!");
      setLoading(false);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    } catch (error) {
      toast.error("Ocorreu um erro, por favor tente novamente.");
      setLoading(false);
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
    }
  }

  useEffect(() => {
    name.length >= 1 && setNameFail(false);
    phone.length >= 11 && setPhoneFail(false);
    message.length >= 1 && setMessageFail(false);

    const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    if (email.length >= 1 && emailValid !== null) {
      setEmailFail(false);
    }

    if (
      name.length >= 1 &&
      email.length >= 1 &&
      emailValid !== null &&
      phone.length >= 11 &&
      message.length >= 1
    ) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  }, [email, email.length, message.length, name.length, phone.length]);

  return (
    <Container id="home">
      <Header />
      <SectionWhite>
        <SectionInside className="container" id="about">
          <Title>SOBRE</Title>

          <InsideAbout>
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <img src={ImageAbout} alt="RpSoft" className="img-fluid mb-3" />
              </div>
              <div className="col-lg-8 col-md-8">
                <p>
                  <strong>A RPSoft Sistemas</strong> atua há 21 anos no mercado.
                  Ao longo deste tempo nossa equipe adquiriu uma larga
                  experiência no desenvolvimento de soluções e de habilidades
                  para execução e implantação de projetos com custos e prazos
                  competitivos, permitindo assim que pequenas e médias empresas
                  obtenham mais eficiência e eficácia no seu dia a dia.
                </p>

                <p>
                  Oferecemos soluções completas em softwares de Automação
                  Comercial e Gerenciamento de Empresas. Os nossos Sistemas são
                  de fácil utilização e entendimento pelos seus usuários.
                </p>

                <p>
                  Estamos presentes em 18 cidades, e atendemos hoje 100
                  clientes, a maioria no Estado de São Paulo, e também nos
                  Estados do Amazonas, Rondônia, Roraima e Pará, nos segmentos
                  de Farmácias, Cerealistas, Transportes, Lojas de Ração,
                  Padarias, Açaí e Estabelecimentos Comerciais em geral.
                </p>
              </div>

              <div className="col-lg-8 col-md-8">
                <p>
                  Nossos sistemas possuem estrutura modular e permitem uma
                  melhor customização e implantação conforme as necessidades da
                  sua empresa.
                </p>

                <p>
                  <strong>
                    Seriedade e profissionalismo são o nosso lema.
                  </strong>
                </p>

                <p>
                  Procuramos sempre superar as expectativas dos clientes através
                  do comprometimento com a excelência, melhor atendimento e
                  rapidez nas soluções de problemas.
                </p>

                <p>
                  Isto é o que nos move, nos motiva e nos credencia para ser a
                  solução para a sua empresa!
                </p>
              </div>
              <div className="col-lg-4 col-md-4">
                <img src={ImageAbout2} alt="RpSoft" className="img-fluid" />
              </div>
            </div>
          </InsideAbout>
        </SectionInside>
      </SectionWhite>

      <SectionAboutContainerParallax>
        <div className="black-cover">
          <div className="container">
            <div className="cardInside">
              <Title>MISSÃO</Title>
              <p>
                Apresentar soluções para a gestão de pequenas e médias empresas,
                contribuindo para o aumento da produtividade e lucratividade
                antecipando o futuro para a realidade de hoje dentro das
                empresas. Oferecer Produtos com excelência em qualidade, e
                prestar um ótimo atendimento para os nossos clientes.
              </p>
            </div>

            <div className="cardInside">
              <Title>VISÃO</Title>
              <p>
                Buscamos ser uma empresa capaz de entregar aos nossos clientes
                um sistema que promova melhoria dos processos internos,
                eliminando atividades trabalhosas e o retrabalho diário,
                ajudando os nossos clientes a se tornarem empresas mais
                competitivas. Trabalhamos no aprimoramento em termos de
                tecnologia e automação. Queremos desenvolver novas formas de
                realizar uma gestão afetiva fazendo parte de perto do dia a dia
                dos nossos clientes. <br /> Buscamos Segurança apresentando
                sempre correções e atualizações periódicas para inibir erros e
                falhas, Agilidade nos processos e tomadas de decisões com telas
                objetivas e de fácil utilização, e Suporte com uma Equipe de
                Analistas especializados para melhor atendê-los.
              </p>
            </div>

            <div className="cardInside">
              <Title>VALORES</Title>
              <p>
                Comprometimento · Humildade · Inovação · Respeito · Confiança
              </p>
            </div>
          </div>
        </div>
      </SectionAboutContainerParallax>

      <SectionSilver id="solutions">
        <SectionInside>
          <Title>SOLUÇÕES PARA SUA EMPRESA</Title>
          <div className="container">
            <div className="row mt-5">
              <div className="col-lg-12 col-md-12">
                <SolutionsCards className="shadow-sm">
                  <h2>COMGER</h2>
                  <h4>Gerenciador para Comércios em Geral</h4>
                  <hr />

                  <p>
                    Nós temos a solução perfeita para que você possa administrar
                    o seu Comércio com todas as ferramentas necessárias, e tenha
                    total controle do seu estoque, controle de suas vendas,
                    agilidade no seu frente de caixa e muito mais. Integre o seu
                    Caixa com o seu Estoque. Controle o seu financeiro e tenha
                    uma melhor gestão em seu dia-a-dia.
                  </p>

                  <p>
                    Indicado para empresas de pequeno porte e que trabalhe com
                    vendas no varejo.
                  </p>
                  <p>Tenha um Suporte Local, Suporte Remoto e Personalizado.</p>

                  <h5>Principais módulos do programa</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> Conta-Corrente
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Parcelamento
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Orçamento
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Consignado
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Sat Fiscal
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Nota Fiscal Eletrônica
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Comanda Eletrônica
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Contas à Pagar
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Contas a receber
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de duplicatas
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Estoque
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Caixa
                    </li>
                  </ul>

                  <h5>Módulos Adicionais</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check-double"></i> Ordem de Serviço
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> Açaí
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> Lanches e
                      Pizzarias
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> Locação e Serviços
                    </li>
                  </ul>

                  <h5>Integradores</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check-double"></i> COMVEN - Venda
                      Balcão
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> FWSAT - Integrador
                      Sat
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> COMVENGAR -
                      Integrador Smart para Restaurante
                    </li>
                  </ul>
                  <h5>Em quais segmentos pode ser utilizado?</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i>Restaurantes
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Padarias
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Açaí
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Minimercados
                    </li>
                    <li>
                      <i className="fas fa-check"></i>Lojas do Comércio em Geral
                    </li>
                  </ul>
                </SolutionsCards>
              </div>

              <div className="col-lg-12 col-md-12">
                <SolutionsCards className="shadow-sm">
                  <h2>VisualFW</h2>
                  <h4>Gerenciador para Farmácias e Drogarias</h4>
                  <hr />
                  <p>
                    Uma farmácia ou drogaria, só atinge o sucesso com uma
                    administração eficiente, e é isso que o VisualFW tem a
                    oferecer, permitindo o gerenciamento das principais rotinas
                    de maneira fácil, simples e eficiente, tornando o controle
                    mais preciso e confiável.
                  </p>
                  <p>
                    Totalmente adequado as exigências legais, e atuando há 20
                    anos nesse ramo, o sistema foi feito com um grande
                    conhecimento das rotinas próprias do setor.
                  </p>
                  <h5>Principais módulos do programa</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> VisuaFWECF – Vendas SAT
                      Fiscal
                    </li>
                    <li>
                      <i className="fas fa-check"></i> VisualV5 – Terminal de
                      Balcão
                    </li>
                    <li>
                      <i className="fas fa-check"></i> FWPre – Atualização
                      online de preços de medicamentos
                    </li>
                    <li>
                      <i className="fas fa-check"></i> LivroPS – Livro
                      Eletrônico de Controle de Psicotrópicos e antimicrobianos
                    </li>
                    <li>
                      <i className="fas fa-check"></i> VisuFWNF-e – Nota Fiscal
                      Eletrônica
                    </li>
                  </ul>
                  <h5>Para Empresas que tem mais de uma loja</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check-double"></i> VisuFWMAT –
                      Gerenciador Multi-lojas
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> Integraf –
                      Integrador para sistema Multi-lojas
                    </li>
                  </ul>
                  <h5>Somos Homologados</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> NF-e - Nota Fiscal
                      Eletrônica
                    </li>
                    <li>
                      <i className="fas fa-check"></i> TEF - Transferência
                      Eletrônica de Fundos – Cartões de Crédito
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Trncenter - Novartis e
                      outros
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Vidalink
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Farmalink
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Epharma
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Farmácia Popular -
                      Datasus
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Prev-Saúde
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Funcional-Card
                    </li>
                  </ul>
                </SolutionsCards>
              </div>

              <div className="col-lg-12 col-md-12">
                <SolutionsCards className="shadow-sm">
                  <h2>Transco</h2>
                  <h4>Gerenciador Comercial Completo para Empresas</h4>
                  <hr />
                  <p>
                    Para que sua empresa tenha o controle total dos processos,
                    apresentamos um sistema capaz de gerenciar a sua empresa por
                    completo, desenvolvido a principio para o ramo de Cerealista
                    e tudo o que envolve esse ramo, mas aos poucos se tornou um
                    sistema capaz de gerenciar qualquer comércio parecido.
                  </p>

                  <p>
                    Um sistema muito completo, inclusive com a possibilidade de
                    trabalhar com vendedores externos integrados.
                  </p>
                  <p>
                    Indicado para empresas de médio porte e que trabalhe com
                    vendas no atacado.
                  </p>

                  <h5>Principais módulos do programa</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check"></i> Controle de Estoque
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Contas à pagar -
                      duplicatas e despesas
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Contas à receber - vendas
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Transferência para filial
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Consignado
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Formação de Custo
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle Bancário
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Caixa Geral
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Caixa Administrativo
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Caixa Vendas
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Quebra de
                      Estoque
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Cheque
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Cheques
                      devolvidos
                    </li>
                    <li>
                      <i className="fas fa-check"></i> Controle de Pedido
                      Pendentes
                    </li>
                  </ul>

                  <h5>Módulos Adicionais</h5>
                  <ul>
                    <li>
                      <i className="fas fa-check-double"></i> Controle de
                      filiais
                    </li>
                    <li>
                      <i className="fas fa-check-double"></i> App Mvendedor para
                      vendedores externos
                    </li>
                  </ul>
                </SolutionsCards>
              </div>
            </div>
          </div>
        </SectionInside>
      </SectionSilver>

      <SectionContact active={btnActive} id="contact">
        <div className="container">
          <Title>CONTATO</Title>
          <div className="mt-5">
            {/* <div className="col-lg-8 col-md-8">
              <form>
                <div className="form-group">
                  <ContactInput
                    fail={nameFail}
                    type="text"
                    placeholder="Nome completo"
                    onChange={e => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="form-group">
                  <ContactInput
                    fail={emailFail}
                    type="email"
                    placeholder="E-mail"
                    onChange={e => setEmail(e.target.value)}
                    value={email}
                  />
                </div>
                <div className="form-group">
                  <ContactInput
                    fail={phoneFail}
                    type="text"
                    placeholder="Celular com DDD"
                    onChange={e => setPhone(e.target.value)}
                    value={phone}
                  />
                </div>
                <div className="form-group">
                  <ContactTextArea
                    fail={messageFail}
                    type="text"
                    placeholder="Mensagem"
                    rows="5"
                    onChange={e => setMessage(e.target.value)}
                    value={message}
                  />
                </div>
                <div className="form-group">
                  <button
                    type="button"
                    className={`btn ${
                      btnActive ? "btn-primary" : "btn-outline-secondary"
                    }`}
                    onClick={btnActive === true ? handleSubmit : null}
                    disabled={!btnActive}
                  >
                    {loading ? (
                      <Loader
                        type="ThreeDots"
                        color="#FFFFFF"
                        height={20}
                        width={20}
                      />
                    ) : (
                      "Enviar"
                    )}
                  </button>
                </div>
              </form>
            </div> */}

            <div className="col-lg-12 col-md-12">
              <div className="row">
                <ContactIcon className="col-lg-4">
                  <i className="fab fa-whatsapp"></i>
                  <div>
                    <p>WHATSAPP</p>
                    <a href="https://wa.me/5519989407822" target="_blank">
                      19 98940 7822
                    </a>
                  </div>
                </ContactIcon>

                <ContactIcon className="col-lg-4">
                  <i className="fas fa-phone-square-alt"></i>
                  <div>
                    <p>TELEFONE</p>
                    19 98940 7822
                  </div>
                </ContactIcon>

                <ContactIcon className="col-lg-4">
                  <i className="fas fa-map-marker-alt"></i>
                  <div>
                    <p>ENDEREÇO</p>
                    Avenida Bela Vista 331 A <br /> Vila Formosa <br />
                    São José do Rio Pardo - SP
                  </div>
                </ContactIcon>
              </div>
            </div>
          </div>
        </div>
      </SectionContact>
    </Container>
  );
}
