import styled from "styled-components";

// Images
import BackgroundHeader from "../../assets/images/header.jpg";

export const Container = styled.div`
  background: #fff;
`;

export const HeaderMain = styled.div`
  width: 100%;
  height: 100vh;
  margin: auto;
  background-image: url(${BackgroundHeader});
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  .black-cover {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);

    .inside {
      margin: auto;
      max-width: 980px;
      height: 100vh;
      padding: 60px 40px;
      display: flex;
      flex-direction: column;
    }

    .headerTop {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      min-height: 100px;
    }

    .headerTop > .logo > img {
      height: 150px;
    }

    .textContent {
      width: 100%;
      margin: auto 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      h1.white {
        font-size: 60px;
        color: #fff;
        margin-right: 20px;
      }

      h1.title {
        font-size: 60px;
        color: #0eaaf2;
      }

      p.description {
        margin-top: 30px;
        font-size: 20px;
        line-height: 30px;
        text-align: center;
        color: #fff;
        font-weight: 400;
      }
    }

    nav {
      width: 100%;
      margin-left: 50px;
    }
    nav > ul {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
    nav > ul > a > li {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      overflow: hidden;
      border-bottom: 2px solid transparent;
      color: #fff;
      padding-bottom: 10px;
      font-weight: bold;
      animation: fadeOut 500ms forwards;
    }
    nav > ul > a > li:hover {
      animation: fadeIn 500ms forwards;
      color: #0eaaf2;
      border-bottom: 2px solid #0eaaf2;
      padding-bottom: 10px;
    }

    @keyframes fadeIn {
      from {
        opacity: 0.5;
        transform: translateY(0%);
      }
      to {
        opacity: 1;
        transform: translateY(-20%);
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 0.5;
        transform: translateY(-20%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    @media (max-width: 980px) {
      .inside {
        display: none;
      }
    }

    @media (max-width: 980px) {
      .insideMobile {
        margin: 0 auto;
        width: 100%;
        height: 100vh;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .headerTopMobile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        img {
          width: 200px;
        }

        .menuOpen {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 5px;
          background: #222;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .textContentMobile {
        width: 100%;
        text-align: center;

        h1.white {
          font-size: 30px;
          color: #fff;
        }

        h1.title {
          font-size: 30px;
          color: #0eaaf2;
        }

        p.description {
          margin-top: 30px;
          font-size: 16px;
          line-height: 23px;
          text-align: center;
          color: #fff;
          font-weight: 400;
        }
      }
    }
  }
`;

export const MenuMobileContainer = styled.div`
  display: none;
  @media (max-width: 980px) {
    display: inline;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    width: 70%;
    height: 100vh;
    background: #111;
    padding: 40px;
    animation: ${props => (props.openMenu ? "MenuOpen" : "MenuClose")} 500ms
      forwards;

    @keyframes MenuOpen {
      from {
        opacity: 0.5;
        transform: translateX(+60%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }

    @keyframes MenuClose {
      from {
        opacity: 1;
        transform: translateX(0%);
      }
      to {
        opacity: 0;
        transform: translateX(+100%);
        display: none;
      }
    }

    .closed {
      background: transparent;
      border: 0;
    }

    nav {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    nav > ul > a > li {
      text-align: center;
      font-size: 24px;
      margin-bottom: 30px;
      border-bottom: 4px solid transparent;
      color: #fff;
      padding-bottom: 10px;
      font-weight: bold;
      animation: fadeOut 500ms forwards;
    }
    nav > ul > a > li:hover {
      animation: fadeIn 500ms forwards;
      color: #0eaaf2;
      border-bottom: 4px solid #0eaaf2;
      padding-bottom: 10px;
    }

    @keyframes fadeIn {
      from {
        opacity: 0.5;
        transform: translateX(0%);
      }
      to {
        opacity: 1;
        transform: translateX(-15%);
      }
    }

    @keyframes fadeOut {
      from {
        opacity: 0.5;
        transform: translateX(-15%);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`;
