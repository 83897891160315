import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// Pages - Components
import Main from "./pages/Main";
import Comger from "./pages/Solutions/Comger/";

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={Main} />
        <Route path="/solucoes/comger" component={Comger} />
      </Switch>
    </BrowserRouter>
  );
}
